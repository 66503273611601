import "../CSS/LandingPage.css";
// import TextScramble from "./TextScramble";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const LandingPage = () => {
  return (
    <div className="me">
      <h1 className="notice">
        my site is currently under construction. stay tuned.
      </h1>
      {/* <h2 className="name">Johnny Domingo</h2> */}
      {/* <TextScramble /> */}
      {/* <div className="icons">
          <a
            className="social-link"
            href="https://github.com/johnnydomingo"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faGithub}
              id="git-icon"
              className="hover"
            ></FontAwesomeIcon>
          </a>
          <a
            className="social-link"
            href="https://www.linkedin.com/in/johnny-domingo/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              className="hover"
              id="li-icon"
            ></FontAwesomeIcon>
          </a>
        </div> */}
      {/* </div> */}
    </div>
  );
};
export default LandingPage;
