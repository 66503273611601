import Layout from "./Components/Layout";
// import Footer from "./Components/Footer";
import Landing from "./Screens/Landing";
import { React, useEffect } from "react";
import { gsap } from "gsap";
import "./CSS/App.css";

const App = () => {
  useEffect(() => {
    const $bigBall = document.querySelector(".cursor__ball--big");
    const $smallBall = document.querySelector(".cursor__ball--small");

    // Listeners
    document.body.addEventListener("mousemove", onMouseMove);

    // Move the cursor
    function onMouseMove(e) {
      gsap.to($bigBall, { duration: 0.2, x: e.pageX - 15, y: e.pageY - 15 });
      gsap.to($smallBall, { duration: 0.01, x: e.pageX - 5, y: e.pageY - 7 });
    }
  }, []);

  return (
    <div className="App">
      <div className="cursor">
        <div className="cursor__ball cursor__ball--big ">
          <svg height="30" width="30">
            <circle cx="15" cy="15" r="12" strokeWidth="0"></circle>
          </svg>
        </div>
        <div className="cursor__ball cursor__ball--small">
          <svg height="12" width="12">
            <circle cx="5" cy="5" r="4" strokeWidth="0"></circle>
          </svg>
        </div>
      </div>
      <Layout>
        <Landing />
        {/* Commenting out while under construction 12/10/2022 */}
        {/* <Footer /> */}
      </Layout>
    </div>
  );
};

export default App;
